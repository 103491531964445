import React from 'react';
import { PageProps } from 'gatsby';

import Container from '../components/layout/Container';
import Layout from '../components/layout/Layout';
import MetaData from '../components/seo/MetaData';
import OrganizationStructuredData from '../components/seo/OrganizationStructuredData';
import SmallContainer from '../components/layout/SmallContainer';

const KategorierPage: React.FunctionComponent<PageProps> = () => {
  
  return (
    <Layout>
      <MetaData title="Privatpolitik" />
      <OrganizationStructuredData />
      <div className="bg-white">
        <Container className="pt-4 text-center">
          <h1>Privatpolitik</h1>
          <hr className="w-48 text-center mx-auto mb-2 border-2" />
        </Container>
        <SmallContainer>
            <p>
                Hos vegetar.dk respekterer og beskytter vi dine personlige oplysninger og deler dem ikke med en tredjemand. 
                Læs vores privatpolitik nedenfor. Denne privatpolitik regulerer måden, hvorpå vegetar.dk indsamler, opbevarer og anvender oplysninger indsamlet fra brugere af hjemmesiden www.vegetar.dk. 
                Denne privatpolitik omfatter hjemmesiden og alle produkter tilbudt af vegetar.dk.
            </p>
            <h3>
                Personlige oplysninger
            </h3>
            <p>
                Vi kan indsamle personlige oplysninger fra brugere på en række forskellige måder, inklusiv men ikke begrænset til, når brugere benytter vores software til at oprette en finde opskrifter samt andre aktiviteter på hjemmesiden. 
                Brugere kan blive spurgt om navn, e-mailadresse, postadresse efter behov. Brugere kan dog besøge hjemmesiden anonymt og vi vil udelukkende indsamle personlige oplysninger fra brugere, når de frivilligt giver sådanne oplysninger til os. 
                Brugere kan altid vælge ikke at give personlige oplysninger, men dette kan forhindre dem i at benytte visse af hjemmesidens tjenesteydelser eller funktionaliteter.
            </p>
            <h3>
                Ikke-Personlige oplysninger
            </h3>
            <p>
              Vi kan indsamle ikke-personlige oplysninger om brugere, så snart de besøger hjemmesiden. 
              Ikke-personlige oplysninger kan omfatte browser-navn, computertype og tekniske oplysninger om brugerens forbindelsesmåde med hjemmesiden, såsom styresystem, internetudbyder og andre lignende oplysninger.
            </p>
            <h3>
              Cookies
            </h3>
            <p>
              Hjemmesiden benytter "cookies" for at forbedre Brugernes oplevelse. 
              Brugerens webbrowser gemmer cookies i din lokale database til registreringsformål og til tider for at tracke oplysninger om dem. Brugere kan vælge at sætte deres browser til at afvise cookies eller varsle dig, når cookies bliver sendt. 
              Brugeren skal dog være opmærksom på, at visse dele af hjemmesiden herefter kan have nedsat funktionalitet.
            </p>
            <h3>
              Hvordan beskytter vi dine oplysninger?
            </h3>
            <p>
              Vi har indført passende processer for indsamling, opbevaring og behandling af data for at beskytte mod uautoriseret adgang, ændring, offentliggørelse eller destruktion af dine personlige oplysninger og data opbevaret på vegetar.dk. 
            </p>
        </SmallContainer>
      </div>
    </Layout>
  );
};

export default KategorierPage;